import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import {
  JOB_STORE_PROVIDERS,
  MenuItemComponent,
} from '@sites/dashboard/feature-job';
import {
  ROOT_STORE_PROVIDERS,
  effects,
  reducers,
} from '@sites/dashboard/store';
import { UiFormlyModule } from '@sites/dashboard/ui-formly';
import { DataAuthModule } from '@sites/data-auth';
import { DataConnectModule } from '@sites/data-connect';
import { ENVIRONMENT } from '@sites/util-environment';
import { UtilErrorsModule } from '@sites/util-errors';
import { UtilNavigationModule } from '@sites/util-navigation';
import {
  MARKED_OPTIONS,
  MarkdownModule,
  MarkedOptions,
  MarkedRenderer,
} from 'ngx-markdown';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';

// configure the markdown renderer.
function markdownOptions(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const linkRenderer = renderer.link;
  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(
      /^<a /,
      '<a role="link" target="_blank" rel="nofollow noopener nereferrer" '
    );
  };
  return {
    renderer,
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UtilNavigationModule.forRoot({
      titleSuffix: 'Dashboard',
      titleSeparator: ' | ',
    }),
    DataConnectModule.forRoot({
      baseUrl: environment.apiUrl,
    }),
    DataAuthModule.forRoot({
      authority: environment.authAuthority,
      redirectUrl: environment.authRedirectUrl,
      postLogoutRedirectUrl: environment.authPostLogoutRedirectUrl,
      unauthorizedRoute: environment.authUnauthorizedRoute,
      clientId: environment.authClientId,
    }),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markdownOptions,
      },
    }),
    UtilErrorsModule.forRoot(),
    StoreModule.forRoot({
      ...reducers,
    }),
    EffectsModule.forRoot([...effects]),
    MenuItemComponent,
    StoreRouterConnectingModule.forRoot(),
    // Reduce bundle size by only bundling modules needed for an environment
    ...environment.appImports,
    DefaultLayoutComponent,
    BlankLayoutComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    UiFormlyModule,
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: {
        hasBackdrop: true,
        maxWidth: '48rem',
        width: '100%',
        autoFocus: 'first-tabbable',
        restoreFocus: true,
      },
    },
    ...ROOT_STORE_PROVIDERS,
    ...JOB_STORE_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
