// @generated by protoc-gen-connect-es v1.6.1
// @generated from file hmm/ninja/audience.proto (package hmm.ninja, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetAudienceRequest, GetAudienceResponse, ListAudiencesRequest, ListAudiencesResponse, ListAudienceTargetDetailUsagesRequest, ListAudienceTargetDetailUsagesResponse } from "./audience_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service hmm.ninja.AudienceService
 */
export const AudienceService = {
  typeName: "hmm.ninja.AudienceService",
  methods: {
    /**
     * GetAudience gets a single audience by survey id
     *
     * @generated from rpc hmm.ninja.AudienceService.GetAudience
     */
    getAudience: {
      name: "GetAudience",
      I: GetAudienceRequest,
      O: GetAudienceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListAudiences gets a list of audiences by survey ids or survey audience id
     *
     * @generated from rpc hmm.ninja.AudienceService.ListAudiences
     */
    listAudiences: {
      name: "ListAudiences",
      I: ListAudiencesRequest,
      O: ListAudiencesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListAudienceTargetDetailUsages will get a list of target details, how frequently they've been used, and then they were last used
     *
     * @generated from rpc hmm.ninja.AudienceService.ListAudienceTargetDetailUsages
     */
    listAudienceTargetDetailUsages: {
      name: "ListAudienceTargetDetailUsages",
      I: ListAudienceTargetDetailUsagesRequest,
      O: ListAudienceTargetDetailUsagesResponse,
      kind: MethodKind.Unary,
    },
  }
};

